import React from "react";
import "./Mapa.css";
function Mapa() {
  return (
    <div className="mapa-container">
      <iframe
        className="mapa"
        title="Localização"
        width="100%"
        height="350"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15877.496209140723!2d-35.2044721!3d-5.802731499999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7b30003309f831f%3A0xbfe568245c28420e!2sAv.%20Romualdo%20Galv%C3%A3o%2C%20293%20-%20Lagoa%20Nova%2C%20Natal%20-%20RN%2C%2059020-640!5e0!3m2!1spt-BR!2sbr!4v1715824738207!5m2!1spt-BR!2sbr"
        referrerpolicy="no-referrer-when-downgrade">
      </iframe>
    </div>
  );
}

export default Mapa;
