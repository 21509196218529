import React from "react";
import "./Contato.css";
import Logo from "../../assets/imgs/isanome.svg";

function Contato() {
  return (
    <div className="contato-container">
      <div className="contato-img-container">
        <img className="logo-isa" src={Logo} alt="logo"></img>
      </div>
      <div className="contato-content">
        <span>
          <i class="uil uil-phone"></i>
          <p>(84) 99132-1514</p>
        </span>

        <span>
          <i class="uil uil-at"></i>
          <p>isadoramf.adv@gmail.com</p>
        </span>

        <span>
          <i class="uil uil-instagram"></i>
          <p>@isadoram.f</p>
        </span>

        <span>
          <i class="uil uil-map-marker"></i>
          <p>
          Endereço: Av. Romualdo Galvão, 293, Sl.301, Tirol, Natal-RN.
          </p>
        </span>

        <a href="https://wa.me/5584991321514" target="_blank" rel="noreferrer" className="main-btn">
          Fale comigo pelo WhatsApp
        </a>
      </div>
    </div>
  );
}

export default Contato;
