import React from "react";
import "./Sobre.css";
import Perfil from '../../assets/imgs/fotooficial.jpg';

function Sobre() {
  return (
    <div className="sobre-wrapper">
      <div className="sobre-container">
        <div className="sobre-image">
          <img className="adv-img"
              src={Perfil}
              alt='Advogada'
            />
          <h3>Isadora Maia - OAB/RN 20703</h3>
        </div>
        <div className="sobre-text">
          <h2>Áreas de atuação.</h2>
          
          <div>
            <ul>
              <li>• Planejamento Sucessório</li>
              <li>• Direito da Saúde</li>
              <li>• Direito Previdenciário</li>
              <li>• Direito do Consumidor</li>
              <li>• Direito de Família</li>
              <li>• Direito do Trabalho</li>
              <li>• Direito Imobiliário</li>
            </ul>
          </div>

          <div className="sobre-btn-container">
            <a
              href="https://wa.me/5584991321514"
              target="_blank"
              rel="noreferrer"
              className="main-btn"
            >
              Saiba mais
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sobre;
