import React from "react";
import "./Hero.css";
import HeroLogo from "../../assets/imgs/logoisa.svg";


function Hero() {
  return (
    <div className="hero-wrapper">

      <div className="image-overlay"></div>
      <div className="hero-container hero-section">
        <div className="hero-content text-container">
          <h1>Especialista em direito civil e processo civil</h1>
          <p>
          Como advogada especializada, estou aqui para oferecer orientação jurídica sólida e soluções personalizadas em todas essas áreas: consumidor, família, planejamento sucessório, previdenciário, trabalhista, imobiliário e saúde.
          </p>
          <a href="https://wa.me/5584991321514" target="_blank" rel="noreferrer" className="main-btn">Tire suas dúvidas agora!</a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
