import React, { useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from './components/shared/Header';
import Footer from './components/shared/Footer';
import Hero from './components/hero/Hero.js';
import Diferenciais from './components/diferenciais/Diferenciais.js';
import Mapa from "./components/mapa/Mapa.js";
import FAQ from './components/faq/Faq.js';
import Sobre from './components/sobre/Sobre.js';
import Servicos from './components/servicos/Servicos.js';
import Contato from './components/contato/Contato.js';
import WhatsAppLogo from './assets/imgs/whatsBlack.svg';
import CTA from './components/cta/Cta.js';

import './App.css';
import './assets/styles/Reset.css';

function App() {

  const HeroRef = useRef();
  const FaqRef = useRef();
  const ContatoRef = useRef();
  const SobreRef = useRef();
  const ServicosRef = useRef();

  const scrollToComponent = (refName) => {
    const refs = {
      HeroRef,
      FaqRef,
      ContatoRef,
      SobreRef,
      ServicosRef
    };

    const selectedRef = refs[refName];

    if (selectedRef && selectedRef.current) {
      var yOffset;
      if (refName === "HeroRef") {
        yOffset = -180;
      } else {
        yOffset = -120;
      }

      const yCoordinate =
        selectedRef.current.getBoundingClientRect().top +
        window.scrollY +
        yOffset;
      window.scrollTo({ top: yCoordinate, behavior: "smooth" });
    }
  };

  const main = '/';

  return (
    <Router>
      <div className='App'>

        <Header scrollToComponent={scrollToComponent} />

        <div class='body-wrapper'>
          <div class='body-container'>

            <div ref={HeroRef}>
              <Hero />
            </div>

            <div ref={ServicosRef}>
              <Servicos />
            </div>

            <div ref={SobreRef}>
              <Sobre />
            </div>


        {/*     <Diferenciais /> */}

            <div ref={FaqRef}>
              <FAQ />
            </div>

            <div ref={ContatoRef}>
              <Contato />
            </div>



            <Mapa />


          </div>
        </div>

        <div class="whats-btn"><a href="https://wa.me/5584991321514" alt="whatsapp link" target="_blank" rel="noreferrer" aria-label="WhatsApp button"><i className="uil uil-whatsapp"></i></a></div>

        <Footer scrollToComponent={scrollToComponent} />

      </div>
    </Router>
  );
}

export default App;

