// Menu.js
import React, { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './Menu.css';
import Close from '../../assets/imgs/close.svg';

const Menu = ({ toggleSidebar, isOpen, setIsOpen, scrollToComponent }) => {

  const sidebarRef = useRef(null);

  const scrollAction = (address) => {
    scrollToComponent(address);
    toggleSidebar();
  };

  const closeSidebar = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeSidebar);
    return () => {
      document.removeEventListener('mousedown', closeSidebar);
    };
  });

  return (
    <>
      <CSSTransition in={isOpen} timeout={500} classNames='fade' unmountOnExit>
        <div>
          <div className='sidebar' ref={sidebarRef}>
            <button
              className='menu-toggle close-menu-btn'
              onClick={toggleSidebar}
            >
              <img src={Close} alt='Close' />
            </button>

            <div className='menu-btns'>
              <button onClick={() => scrollAction('HeroRef')} alt='home'>
                Início
              </button>

              <button onClick={() => scrollAction('ServicosRef')} alt='home'>
                Áreas de atuação
              </button>

              <button onClick={() => scrollAction('SobreRef')} alt='home'>
                Sobre
              </button>

              <button onClick={() => scrollAction('FaqRef')} alt='home'>
                Perguntas Frequentes
              </button>

              <button
                onClick={() => scrollAction('ContatoRef')}
                alt='home'
              >
                Contato
              </button>

            </div>
          </div>
          <div className='curtain'></div>
        </div>
      </CSSTransition>
    </>
  );
};

export default Menu;

