import React, { useState } from 'react';
import './Faq.css';

function FAQ() {
  const faqData = [
    {
      question: 'Qual é a importância de um testamento em um planejamento sucessório?',
      answer:
        'Um testamento permite que você especifique como seus bens serão distribuídos após sua morte, evitando disputas e garantindo que seus desejos sejam respeitados.'
    },
    {
      question: 'Como faço para requerer aposentadoria por tempo de contribuição?',
      answer:
        'É necessário cumprir os requisitos de idade e tempo de contribuição estabelecidos pelo sistema previdenciário do seu país e entrar com o pedido junto ao órgão competente.'
    },
    {
      question: 'Quais são os documentos necessários para a compra de um imóvel?',
      answer:
        'Normalmente incluem RG, CPF, comprovante de residência, comprovante de estado civil, além de documentos específicos do imóvel, como escritura e certidões negativas.'
    },
    {
      question: 'Como proceder em caso de divórcio e guarda compartilhada?',
      answer:
        'Agende uma consulta para discutir suas opções legais e encontrar a melhor solução para sua família. Estou aqui para ajudar a garantir que seus direitos sejam protegidos e seus interesses sejam atendidos. Entre em contato para dar o próximo passo.'
    },
    {
      question: 'Como calcular as horas extras trabalhadas?',
      answer:
        'Multiplique o valor da hora normal pelo percentual de acréscimo estabelecido por lei para horas extras, considerando os limites diários e semanais de jornada de trabalho. Se precisar de ajuda para garantir seus direitos trabalhistas, entre em contato agora mesmo para uma consulta.'
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-wrapper">
      <div className='faq-container'>
        <h2>Perguntas Frequentes</h2>
        <div className='faq-list'>
          {faqData.map((item, index) => (
            <div key={index} className='faq-item'>
              <div
                className={`faq-question ${activeIndex === index ? 'active' : ''}`}
                onClick={() => handleToggle(index)}
              >
                <div className='question-text'>{item.question}</div>
                <div className='toggle-icon'>{activeIndex === index ? '-' : '+'}</div>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FAQ;
