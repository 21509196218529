import React from "react";
import "./Servicos.css";
import servicosData from "./servicos.json"; // Certifique-se de criar esse arquivo de estilo se necessário.

function ServicosCard() {
  return (
    <div className="servicos-wrapper">
      <div className="servicos-card">
        <h2>Alguns de Meus Serviços</h2>
        <ul>
          {servicosData.map((servico, index) => (
            <li key={index}>
              <h3>{servico.titulo}</h3>
              <p>{servico.texto}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default ServicosCard;
